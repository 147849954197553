
import { useRoute, useRouter } from "vue-router"
import { computed, reactive, ref, watch } from "vue"
import ComputerService from "@/services/ComputerService"
import ComputerRunConfiguration from "@/components/computers/tools/ComputerRunConfiguration.vue"
import ComputerToolkitVersion from "./tools/ComputerToolkitVersion.vue"
import ComputerSyncTime from "@/components/computers/tools/ComputerSyncTime.vue"
import useTimeUtils from "@/utils/time"
import ComputerUpdateTime from "@/components/computers/tools/ComputerUpdateTime.vue"
import store from "@/store"
import { state } from "@/utils/state"
import { useStore } from "vuex"
import useAppUtils from "@/utils/app"
import TabsComponent from "../navigation/TabsComponent.vue"
import ChangeCompanyForm from "../companies/ChangeCompanyForm.vue"

export default {
  components: {
    ComputerUpdateTime,
    ComputerSyncTime,
    ComputerRunConfiguration,
    ComputerToolkitVersion,
    TabsComponent,
    ChangeCompanyForm,
  },
  setup(props: any) {
    const store = useStore()
    const route = useRoute()
    const router = useRouter()
    const { absoluteDateTime } = useTimeUtils()
    const computer: any = reactive({})
    const computerService = new ComputerService()
    const computerId = computed(() => route.params.computer_id as string)
    const loadingComp = ref(false)
    const { isMobile } = useAppUtils()
    /*const teamViewerItem = computed(() => [
      {
        label: computer ? computer.teamViewerClientId : null,
        icon: "pi pi-copy",
        command: () => {
          copyTeamviewerId()
        },
      },
    ])
    const anyDeskItem = computed(() => [
      {
        label: computer ? computer.anyDeskClientId : null,
        icon: "pi pi-copy",
        command: () => {
          copyAnyDeskId()
        },
      },
    ])*/
    const isOnlineInTeamviewer = ref(false)
    const isOnlineInAnydesk = ref(false)
    const hasTeamviewer = ref(true)
    const hasAnyDesk = ref(true)
    const tabItems = reactive([
      { label: "Profile", routeName: "computerProfile" },
      { label: "Organizations", routeName: "computerNetwork" },
      { label: "Remote Access", routeName: "computerRemoteAccess" },
      /*       { label: "Dicom Config", routeName: "computerDicoms" }, */
      {
        label: "Hardware",
        routeName: "connectedHardware",
        attributes: { permissions: '["VSOL"]' },
      },
      { label: "Settings", routeName: "computerSettings" },
      { label: "Logs", routeName: "computerLogs", attributes: { permissions: '["VSOL"]' } },
    ])
    const changeCompanyDialogVisible = ref(false)
    const roles = Object.values(store.getters.getRoles)

    const canChangeCompany = computed(
      () =>
        (roles.includes("SUPER_ADMIN") ||
          roles.includes("ADMIN") ||
          roles.includes("SUPER_SUPPORT")) &&
        !computer?.organizations?.length
    )

    watch(
      () => computerId.value,
      () => loadComputer()
    )

    watch(state.onlineTeamviewer, (newValue) => {
      isOnlineInTeamviewer.value = newValue.indexOf(computer.teamViewerClientId) !== -1
    })

    watch(state.onlineAnydesk, (newValue) => {
      isOnlineInAnydesk.value = newValue.indexOf(computer.anyDeskClientId) !== -1
    })

    loadComputer()

    function loadComputer() {
      if (!computerId.value) {
        return
      }
      hasTeamviewer.value = true
      hasAnyDesk.value = true
      loadingComp.value = true
      computerService.getComputer(computerId.value).then((response) => {
        Object.assign(computer, response.data)
        store.dispatch("setNewComputerHistory", computer)
        if (!computer.teamViewerClientId) {
          hasTeamviewer.value = false
        }
        if (!computer.anyDeskClientId) {
          hasAnyDesk.value = false
        }

        if (state.onlineTeamviewer && state.onlineTeamviewer.length) {
          isOnlineInTeamviewer.value =
            state.onlineTeamviewer.indexOf(computer.teamViewerClientId) !== -1
        }

        if (state.onlineAnydesk && state.onlineAnydesk.length) {
          isOnlineInAnydesk.value = state.onlineAnydesk.indexOf(computer.anyDeskClientId) !== -1
        }
        loadingComp.value = false
      })
    }

    function onClickTeamviewer() {
      if (hasTeamviewer.value) {
        window.open("tvcontrol1://control?device=" + computer.teamViewerClientId, "_blank")
      }
    }

    function onClickAnyDesk() {
      if (hasAnyDesk.value) {
        window.open("anydesk:" + computer.anyDeskClientId, "_blank")
      }
    }

    function copyTeamviewerId() {
      navigator.clipboard.writeText(computer.teamViewerClientId)
    }

    function copyAnyDeskId() {
      navigator.clipboard.writeText(computer.anyDeskClientId)
    }

    let showOrgsDropdown = ref()
    let inDropdown = ref(false)
    let recentOrgs = store.getters.getLastOrgs
    let firstOrgFromHistory = ref(null)

    function setFirstOrgFromHistory() {
      if (computer.organizations.length) {
        if (recentOrgs.length) {
          for (let recentOrg = 0; recentOrg < recentOrgs.length; recentOrg++) {
            const foundOrg = computer.organizations.find(
              (org: any) => org.id === recentOrgs[recentOrg].id
            )
            if (foundOrg) {
              firstOrgFromHistory.value = foundOrg
              return firstOrgFromHistory.value
            }
          }
        }
        firstOrgFromHistory.value = computer.organizations[0]
        return firstOrgFromHistory.value
      }
    }

    function cutOrgFromList() {
      return computer.organizations.filter((item: any) => item.id !== firstOrgFromHistory.value.id)
    }

    function toggleOrgOn(event: any) {
      if (computer.organizations.length > 1) {
        inDropdown.value = false
        showOrgsDropdown.value.show(event)
      }
    }

    function toggleOrgOff(event: any) {
      showOrgsDropdown.value.hide(event)
      inDropdown.value = false
    }

    function keepOrgsOpen() {
      inDropdown.value = true
    }

    function delayedToggleForFirst(event: any) {
      setTimeout(() => {
        if (inDropdown.value == false) {
          showOrgsDropdown.value.hide(event)
        }
      }, 100)
    }

    const goToOrg = function (org: any) {
      router.push({ name: "organization", params: { organization_id: org.id } })
    }

    function reload() {
      changeCompanyDialogVisible.value = false
      loadComputer()
    }

    return {
      tabItems,
      computer,
      loadComputer,
      onClickTeamviewer,
      onClickAnyDesk,
      absoluteDateTime,
      //teamViewerItem,
      //anyDeskItem,
      loadingComp,
      state,
      toggleOrgOn,
      toggleOrgOff,
      goToOrg,
      showOrgsDropdown,
      delayedToggleForFirst,
      keepOrgsOpen,
      firstOrgFromHistory,
      setFirstOrgFromHistory,
      cutOrgFromList,
      recentOrgs,
      isOnlineInTeamviewer,
      isOnlineInAnydesk,
      hasAnyDesk,
      hasTeamviewer,
      isMobile,
      reload,
      changeCompanyDialogVisible,
      canChangeCompany,
      computerService,
      computerId,
    }
  },
}
